<template>
  <div class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/enrichment-management" aria-current="true">enrichment-management</router-link></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="title">Enrichment Management</h1>
      <div class="column is-12">
      <button class="button is-primary" @click="openCreateModal">Create Enrichment</button>
      </div>
     <div v-if="showBanner" class="notification is-info m-5">
        <button class="delete" @click="closeBanner"></button>
        <strong>New enrichment item created:</strong>
        <p>{{ newEnrichment }}</p>
      </div>
      <table class="table is-small mt-5">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Default Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="enrichment in enrichments" :key="enrichment.id">
            <td class="is-size-7">{{ enrichment.field_name }}</td>
            <td class="is-size-7">{{ enrichment.default_value }}</td>
            <td class="is-size-7">
              <button class="button is-info is-small ml-2" @click="openEditModal(enrichment)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <create-edit-enrichment-modal
      v-if="isModalOpen"
      :is-edit="isEdit"
      :enrichment="selectedEnrichment"
      @close="closeModal"
      @submit="handleModalSubmit"
      @delete="deleteEnrichment"
    ></create-edit-enrichment-modal>
  </div>
</template>

<script>
import axios from 'axios';
import CreateEditEnrichmentModal from './CreateEditEnrichmentModal.vue'; // Assuming you create a separate component for the modal
import { toast } from 'bulma-toast';

export default {
  components: {
    CreateEditEnrichmentModal,
  },
  data() {
    return {
      enrichments: [],
      isModalOpen: false,
      isEdit: false,
      selectedEnrichment: null,
      showBanner: false,
      newEnrichment: '',
    };
  },
  methods: {
    openCreateModal() {
      this.isEdit = false;
      this.selectedEnrichment = null;
      this.isModalOpen = true;
    },
    openEditModal(enrichment) {
      this.isEdit = true;
      this.selectedEnrichment = { ...enrichment };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleModalSubmit(enrichment) {
      if (this.isEdit) {
        this.updateEnrichment(enrichment);
      } else {
        this.createEnrichment(enrichment);
      }
    },
    createEnrichment(enrichment) {
      axios.post('/api/v1/enrichment/default/', enrichment, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        this.enrichments.push(response.data);
        this.newEnrichment = `Field Name: ${response.data.field_name}, Default Value: ${response.data.default_value}`;
        this.showBanner = true;
        toast({ message: 'Enrichment item created successfully', type: 'is-success' });
        this.closeModal();
      });
    },
    updateEnrichment(enrichment) {
      axios.put(`/api/v1/enrichment/default/${enrichment.id}/`, enrichment, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        const index = this.enrichments.findIndex(item => item.id === enrichment.id);
        if (index !== -1) {
          this.$set(this.enrichments, index, response.data);
        }
        toast({ message: 'Enrichment item updated successfully', type: 'is-success' });
        this.closeModal();
      });
    },
    deleteEnrichment(enrichmentId) {
      if (confirm('Are you sure you want to delete this enrichment item?')) {
        axios.delete(`/api/v1/enrichment/default/${enrichmentId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        }).then(() => {
          this.enrichments = this.enrichments.filter(enrichment => enrichment.id !== enrichmentId);
          toast({ message: 'Enrichment item deleted successfully', type: 'is-success' });
          this.closeModal();
        });
      }
    },
    closeBanner() {
      this.showBanner = false;
    },
  },
  created() {
    axios.get('/api/v1/enrichment/default/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
      this.enrichments = response.data.results;
    });
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 0.5rem;
}
</style>
