<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeQuickview"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title is-size-5">Add New Customer</p>
        <button class="delete" aria-label="close" @click="closeQuickview"></button>
      </header>
      <section class="modal-card-body is-size-6">
        <form @submit.prevent="saveCustomer">
          <div class="field">
            <label class="label">Name *</label>
            <div class="control">
              <input v-model="customerData.name" class="input" type="text" placeholder="Customer Name" required>
            </div>
          </div>
          <div class="field">
            <label class="label">Identity Type *</label>
            <div class="control">
              <div class="select">
                <select v-model="customerData.identity_type" required>
                  <option v-for="(label, value) in identityTypeChoices" :key="value" :value="value">{{ label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Identity Number *</label>
            <div class="control">
              <input v-model="customerData.identity_number" class="input" type="text" placeholder="Identity Number" required>
            </div>
          </div>
          <div class="field">
            <label class="label">TIN No *</label>
            <div class="control">
              <input v-model="customerData.tin_no" class="input" type="text" placeholder="TIN No" required>
            </div>
          </div>
          <div class="field">
            <label class="label">SST No</label>
            <div class="control">
              <input v-model="customerData.sst_no" class="input" type="text" placeholder="SST No">
            </div>
          </div>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input v-model="customerData.email" class="input" type="email" placeholder="Email">
            </div>
          </div>
          <div class="field">
            <label class="label">MSIC Code</label>
            <div class="control">
              <input v-model="customerData.msic_code" class="input" type="text" placeholder="MSIC Code">
            </div>
          </div>
          <div class="field">
            <label class="label">Business Description</label>
            <div class="control">
              <input v-model="customerData.business_description" class="input" type="text" placeholder="Business Description">
            </div>
          </div>
          <div class="field">
            <label class="label">Country *</label>
            <div class="control">
              <div class="select">
                <select v-model="customerData.country" required>
                  <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Address 1 *</label>
            <div class="control">
              <input v-model="customerData.address1" class="input" type="text" placeholder="Address 1" required>
            </div>
          </div>
          <div class="field">
            <label class="label">Address 2</label>
            <div class="control">
              <input v-model="customerData.address2" class="input" type="text" placeholder="Address 2">
            </div>
          </div>
          <div class="field">
            <label class="label">Address 3</label>
            <div class="control">
              <input v-model="customerData.address3" class="input" type="text" placeholder="Address 3">
            </div>
          </div>
          <div class="field">
            <label class="label">Zipcode *</label>
            <div class="control">
              <input v-model="customerData.zipcode" class="input" type="text" placeholder="Zipcode" required>
            </div>
          </div>
          <div class="field">
            <label class="label">City *</label>
            <div class="control">
              <input v-model="customerData.city" class="input" type="text" placeholder="City" required>
            </div>
          </div>
          <div class="field" v-if="customerData.country === 'Malaysia'">
            <label class="label">State *</label>
            <div class="control">
              <div class="select">
                <select v-model="customerData.state" required>
                  <option v-for="(label, value) in malaysiaStates" :key="value" :value="label">{{ label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field" v-else>
            <label class="label">State *</label>
            <div class="control">
              <input v-model="customerData.state" class="input" type="text" placeholder="State" required>
            </div>
          </div>
          <div class="field">
            <label class="label">Contact Person</label>
            <div class="control">
              <input v-model="customerData.contact_person" class="input" type="text" placeholder="Contact Person">
            </div>
          </div>
          <div class="field">
            <label class="label">Contact Reference</label>
            <div class="control">
              <input v-model="customerData.contact_reference" class="input" type="text" placeholder="Contact Reference">
            </div>
          </div>
          <div class="field">
            <label class="label">Contact Number *</label>
            <div class="control">
              <input v-model="customerData.contact_number" class="input" type="text" placeholder="Contact Number" required>
            </div>
          </div>
          <button class="button is-primary" type="submit">Save</button>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="closeQuickview">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddCustomer',
  props: {
    customer: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        entity_code: '',
        tin_no: '',
        sst_no: '',
        identity_type: '',
        identity_number: '',
        email: '',
        msic_code: '',
        business_description: '',
        address1: '',
        address2: '',
        address3: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'Malaysia',  // Set default country
        contact_person: '',
        contact_reference: '',
        contact_number: '',
        bank_number: '',
        source_ui: true,
      }),
    },
  },
  data() {
    return {
      customerData: { ...this.customer },
      identityTypeChoices: {
        BRN: 'BRN',
        NRIC: 'NRIC',
        PASSPORT: 'PASSPORT',
        ARMY: 'ARMY',
        NA: 'NA',
      },
      countries: [],
      malaysiaStates: {
        '00': 'All States',
        '01': 'Johor',
        '02': 'Kedah',
        '03': 'Kelantan',
        '04': 'Melaka',
        '05': 'Negeri Sembilan',
        '06': 'Pahang',
        '07': 'Pulau Pinang',
        '08': 'Perak',
        '09': 'Perlis',
        '10': 'Selangor',
        '11': 'Terengganu',
        '12': 'Sabah',
        '13': 'Sarawak',
        '14': 'Wilayah Persekutuan Kuala Lumpur',
        '15': 'Wilayah Persekutuan Labuan',
        '16': 'Wilayah Persekutuan Putrajaya',
        '17': 'Not Applicable'
      },
      duplicateCustomer: null,
      showConfirmModal: false,
    };
  },
  created() {
    this.fetchCountries();
  },
  watch: {
    customer: {
      handler(newCustomer) {
        this.customerData = { ...newCustomer };
      },
      deep: true,
    },
  },
  methods: {
    async fetchCountries() {
      try {
        const response = await axios.get('https://restcountries.com/v2/all');
        this.countries = response.data.map(country => ({
          name: country.name,
          code: country.alpha2Code,
        }));
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    generateUUID() {
      return Math.floor(1000000000000000 + Math.random() * 9000000000000000).toString();
    },
    async saveCustomer() {
      try {
        this.customerData.source_ui = true;

        if (!this.customerData.id) {
          this.customerData.entity_code = this.generateUUID();
        }

        let response;
        if (this.customerData.id) {
          response = await axios.put(`/api/v1/customers/${this.customerData.id}/`, this.customerData);
        } else {
          response = await axios.post('/api/v1/customers/', this.customerData);
        }

        this.$emit('customerSaved', response.data);
        this.closeQuickview();
      } catch (error) {
        console.error('Error saving customer:', error);
        if (error.response && error.response.status === 400 && error.response.data.non_field_errors) {
          alert("A customer with these details already exists. Do you want to overwrite?");
        }
      }
    },
    closeQuickview() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-card {
  max-width: 90vh;
  max-height: 80vh;
}
</style>
