<template>
  <div class="group-permission-management">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/permission-management" aria-current="true">permission-management</router-link></li>
      </ul>
    </nav>
    <h1 class="title">Group Permission Management</h1>
    <div class="field">
      <label class="label">Select Group</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedGroup" @change="fetchGroupPermissions">
            <option value="" disabled>Select a group</option>
            <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}</option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="permissions.length > 0">
      <h2 class="subtitle">Permissions</h2>
      <ul>
        <li v-for="permission in sortedPermissions" :key="permission.id" :class="{ 'is-selected': isSelected(permission.id) }">
          <label class="checkbox">
            <input type="checkbox" :value="permission.id" v-model="selectedPermissions">
            {{ permission.name }}
          </label>
        </li>
      </ul>
      <button class="button is-primary mt-5" @click="updateGroupPermissions">Update Permissions</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
  name: 'GroupPermissionManagement',
  data() {
    return {
      groups: [],
      permissions: [],
      allPermissions: [],
      selectedGroup: '',
      selectedPermissions: [],
    };
  },
  computed: {
    sortedPermissions() {
      const selected = this.allPermissions.filter(p => this.selectedPermissions.includes(p.id));
      const unselected = this.allPermissions.filter(p => !this.selectedPermissions.includes(p.id));
      return [...selected, ...unselected];
    },
  },
  methods: {
    async fetchGroups() {
      try {
        const response = await axios.get('/api/v1/groups/');
        this.groups = response.data;
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast({ message: 'Error fetching groups', type: 'is-danger' });
      }
    },
    async fetchAllPermissions() {
      try {
        const response = await axios.get('/api/v1/permissions/');
        this.allPermissions = response.data;
      } catch (error) {
        console.error('Error fetching all permissions:', error);
        toast({ message: 'Error fetching permissions', type: 'is-danger' });
      }
    },
    async fetchGroupPermissions() {
      if (!this.selectedGroup) return;

      try {
        const response = await axios.get(`/api/v1/group-list/${this.selectedGroup}/permissions/`);
        this.permissions = response.data;
        this.selectedPermissions = this.permissions.map(perm => perm.id);
      } catch (error) {
        console.error('Error fetching group permissions:', error);
        toast({ message: 'Error fetching group permissions', type: 'is-danger' });
      }
    },
    async updateGroupPermissions() {
      if (!this.selectedGroup) return;

      try {
        await axios.put(`/api/v1/group-list/${this.selectedGroup}/permissions/`, {
          permissions: this.selectedPermissions,
        });
        toast({ message: 'Permissions updated successfully', type: 'is-success' });
      } catch (error) {
        console.error('Error updating group permissions:', error);
        toast({ message: 'Error updating group permissions', type: 'is-danger' });
      }
    },
    isSelected(permissionId) {
      return this.selectedPermissions.includes(permissionId);
    },
  },
  async created() {
    await this.fetchGroups();
    await this.fetchAllPermissions();
  },
};
</script>

<style scoped>
.group-permission-management {
  max-width: 600px;
  margin: 0 auto;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

li.is-selected {
  font-weight: bold;
}
</style>
