<template>
  <div class="new-document-page">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/create-document" aria-current="true">Create Document</router-link></li>
      </ul>
    </nav>
    <div>
      <div class="column is-12 mb-2">
        <h1 class="title">Create E-Invoice Management</h1>
      </div>
      <div class="columns is-fullheight">
        <div class="column is-5 mt-5">
          <div class="left-half">
            <CreateOrEditInvoice />
          </div>
          <div class="left-half">
            <UploadCSV />
          </div>
        </div>
        <div class="column is-7 is-fullheight">
          <InvoiceTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateOrEditInvoice from '@/components/NewDocument/CreateOrEditInvoice.vue';
import UploadCSV from '@/components/NewDocument/UploadCSV.vue';
import InvoiceTable from '@/components/NewDocument/InvoiceTable.vue';

export default {
  name: 'NewDocumentPage',
  components: {
    CreateOrEditInvoice,
    UploadCSV,
    InvoiceTable
  }
}
</script>

<style scoped>
.new-document-page {
  min-width: 900px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.left-half {
  height: 40vh;
  overflow: auto;
  height: 35vh;
}

.column.is-fullheight {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.invoice-table {
  flex: 1;
  overflow-y: auto;
}
</style>
