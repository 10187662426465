<template>
  <div class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/entity-management" aria-current="true">entity-management</router-link></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="title">Entity Management</h1>
      <div class="column is-size-12">
        <button class="button is-primary" @click="openCreateModal">Create New Entity</button>
      </div>
      <div class="column is-size-12">
        <table class="table is-small is-size-7">
          <thead>
            <tr>
              <th>Name</th>
              <th>Entity Code</th>
              <th>Email</th>
              <th>TIN No</th>
              <th>Identity No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entity in entities" :key="entity.id">
              <td class="is-size-7 is-vcentered">{{ entity.name }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.entity_code }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.email }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.tin_no }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.identity_number }}</td>
              <td class="is-size-7 is-vcentered">
                <button class="button is-info is-small ml-2 is-vcentered" @click="openEditModal(entity)">Update</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <create-edit-modal
      v-if="isModalOpen"
      :is-edit="isEdit"
      :entity="selectedEntity"
      :states="states"
      @close="closeModal"
      @submit="handleModalSubmit"
      @delete="deleteEntity"
    ></create-edit-modal>
  </div>
</template>

<script>
import axios from 'axios';
import CreateEditModal from './CreateEditModal.vue'; // Assuming you create a separate component for the modal
import { toast } from 'bulma-toast';

export default {
  components: {
    CreateEditModal,
  },
  data() {
    return {
      entities: [],
      states: [],
      isModalOpen: false,
      isEdit: false,
      selectedEntity: null,
    };
  },
  methods: {
    loadStates() {
      try {
        this.states = JSON.parse(process.env.VUE_APP_STATE_CODES);
      } catch (error) {
        console.error('Error parsing state codes:', error);
      }
    },
    openCreateModal() {
      this.isEdit = false;
      this.selectedEntity = null;
      this.isModalOpen = true;
    },
    openEditModal(entity) {
      this.isEdit = true;
      this.selectedEntity = { ...entity };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleModalSubmit(entity) {
      if (this.isEdit) {
        this.updateEntity(entity);
      } else {
        this.createEntity(entity);
      }
    },
    createEntity(entity) {
      entity.created_by = localStorage.getItem('userid'); 
      axios.post('/api/v1/enrichment/entities/', entity, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        this.entities.push(response.data);
        toast({ message: 'Entity created successfully', type: 'is-success' });
        this.closeModal();
      })
      .catch(() => {
        toast({ message: 'Error creating entity', type: 'is-danger' });
      });
    },
    updateEntity(entity) {
      axios.put(`/api/v1/enrichment/entities/${entity.id}/`, entity, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        const index = this.entities.findIndex(e => e.id === entity.id);
        if (index !== -1) {
          this.$set(this.entities, index, response.data);
        }
        toast({ message: 'Entity updated successfully', type: 'is-success' });
        this.closeModal();
      })
      .catch(() => {
        toast({ message: 'Error updating entity', type: 'is-danger' });
      });
    },
    deleteEntity(entityId) {
      if (confirm('Are you sure you want to delete this entity?')) {
        axios.delete(`/api/v1/enrichment/entities/${entityId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        })
        .then(() => {
          this.entities = this.entities.filter(entity => entity.id !== entityId);
          toast({ message: 'Entity deleted successfully', type: 'is-success' });
          this.closeModal();
        })
        .catch(() => {
          toast({ message: 'Error deleting entity', type: 'is-danger' });
        });
      }
    },
  },
  created() {
    axios.get('/api/v1/enrichment/entities/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
      this.entities = response.data.results;
    })
    .catch(() => {
      toast({ message: 'Error loading entities', type: 'is-danger' });
    });
    this.loadStates();
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 0.5rem;
}
</style>
