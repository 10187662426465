<template>
    <div class="page-my-account mt-5">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><router-link to="/dashboard">Dashboard</router-link></li>
          <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
        </ul>
      </nav>
  
      <h1 class="title">My account</h1>
  
      <div>
        <img :src="profileImageUrl" alt="User Image" class="user-image" />
      </div>
  
      <strong>Username: </strong>{{ userProfile.username }}<br>
      <strong>Name: </strong>{{ userProfile.first_name }} {{ userProfile.last_name }}<br>
      <strong>Access Type: </strong>{{ userProfile.access_type }}<br>
      <strong>Entities:</strong>
      <ul>
        <li v-for="entity in userProfile.sender_entities" :key="entity.entity_code">{{ entity.name }}</li>
      </ul>
      <strong>Roles:</strong>
      <ul>
        <li v-for="group in userProfile.groups" :key="group.id">{{ group.name }}</li>
      </ul>
  
      <hr>
  
      <div class="buttons">
        <button @click="logout" class="button is-danger">Log out</button>
        <button @click="openPasswordResetModal" class="button is-primary">Set New Password</button>
      </div>

      <div v-if="showPasswordModal" class="modal is-active">
      <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Set New Password</p>
            <button class="delete" aria-label="close" @click="closePasswordResetModal"></button>
          </header>
          <section class="modal-card-body">
            <div class="field">
              <label class="label">New Password</label>
              <div class="control">
                <input v-model="newPassword" class="input" type="password" placeholder="Enter new password">
              </div>
            </div>

            <div class="field">
              <label class="label">Confirm New Password</label>
              <div class="control">
                <input v-model="confirmNewPassword" class="input" type="password" placeholder="Confirm new password">
              </div>
            </div>

            <p v-if="passwordError" class="help is-danger">{{ passwordError }}</p>
          </section>
          <footer class="buttons modal-card-foot">
            <button class="button is-success" @click="submitPasswordReset">Save changes</button>
            <button class="button" @click="closePasswordResetModal">Cancel</button>
          </footer>
        </div>
      </div>


    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { toast } from 'bulma-toast';
  
  export default {
    name: 'MyAccount',
    data() {
      return {
        userProfile: {
          username: '',
          access_type: '',
          first_name: '',
          last_name: '',
          sender_entities: [],
          groups: []
        },
        showPasswordModal: false,
        newPassword: '',
        confirmNewPassword: '',
        passwordError: '',
        selectedFile: null,
        profileImageUrl: '' // URL of the user profile image
      };
    },
    async mounted() {
      await this.getUserProfile();
    },
    methods: {
      async getUserProfile() {
        try {
          const response = await axios.get('/api/v1/user-profile/');
          this.userProfile = response.data;
          this.profileImageUrl = response.data.image || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.shutterstock.com%2Fsearch%2Fdefault-user&psig=AOvVaw1cPtp-HwzNn35qJjRBFWDW&ust=1720610385529000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCICj4qnrmYcDFQAAAAAdAAAAABAJ'; // Use a stock image if no user image is available
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      },
      onFileChange(event) {
        this.selectedFile = event.target.files[0];
      },
      async uploadImage() {
        if (!this.selectedFile) {
          alert('Please select a file to upload.');
          return;
        }
  
        const formData = new FormData();
        formData.append('image', this.selectedFile);
  
        try {
          const response = await axios.put('/api/v1/users/me/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Token ${this.$store.state.token}` // Adjust based on your token management
            }
          });
          this.profileImageUrl = response.data.image;
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      },
      async logout() {
        try {
          await axios.post("/api/v1/token/logout/");
          
          // Clear local storage
          localStorage.removeItem("username");
          localStorage.removeItem("userid");
          localStorage.removeItem("token");
          
          // Clear axios authorization header
          axios.defaults.headers.common["Authorization"] = "";
  
          // Commit Vuex mutation to remove token
          this.$store.commit('removeToken');
  
          // Redirect to home page
          this.$router.push('/');
        } catch (error) {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        }
      },
      openPasswordResetModal() {
      this.showPasswordModal = true;
    },
    closePasswordResetModal() {
      this.showPasswordModal = false;
      this.newPassword = '';
      this.confirmNewPassword = '';
      this.passwordError = '';
    },
    async submitPasswordReset() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.passwordError = 'Passwords do not match.';
        return;
      }

      try {
        await axios.post('/api/v1/user/reset-password-vv/', {
          new_password: this.newPassword,
        });

        toast({
          message: 'Password successfully updated.',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });

        // Close modal and reset inputs
        this.closePasswordResetModal();
      } catch (error) {
        console.error('Error updating password:', error);
        this.passwordError = 'Failed to update password. Please try again.';
      }
    },
    },
  };
  </script>
  
  <style>
  .user-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  </style>
  