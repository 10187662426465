<template>
    <div class="upload-csv-page">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/invoices/upload" aria-current="true">Upload CSV</router-link></li>
            </ul>
        </nav>
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Upload CSV File</h1>
                <form @submit.prevent="uploadCSV">
                    <div class="file has-name is-boxed">
                        <label class="file-label">
                            <input class="file-input" type="file" @change="handleFileUpload" ref="file">
                            <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">Choose a file…</span>
                            </span>
                            <span class="file-name">{{ fileName }}</span>
                        </label>
                    </div>
                    <button class="button is-primary" type="submit" :disabled="!file">Upload</button>
                </form>
                <div v-if="responseMessage">
                    <h2 class="subtitle">Response</h2>
                    <pre>{{ responseMessage }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'UploadCSV',
    data() {
        return {
            file: null,
            fileName: '',
            responseMessage: null
        }
    },
    methods: {
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.fileName = this.file.name;
        },
        async uploadCSV() {
            if (!this.file) return;

            const formData = new FormData();
            formData.append('file', this.file);

            try {
                const response = await axios.post('/api/einvoicing_json/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-csrftoken': 'LMfRCqZF851EkqvB91mJvbDNV1nWHKJ0',
                        'authorization': process.env.VUE_APP_API_KEY,
                        'approval': 'true'
                    }
                });
                // Convert response data to string and remove curly brackets
                const dataString = JSON.stringify(response.data).replace(/[{}]/g, '');
                this.responseMessage = dataString;
            } catch (error) {
                console.error('Error uploading file:', error);
                this.responseMessage = error.response ? JSON.stringify(error.response.data).replace(/[{}]/g, '') : 'An error occurred';
            }
        }
    }
}
</script>

<style scoped>
.upload-csv-page {
    padding: 20px;
}
.file-label {
    margin-bottom: 10px;
}
</style>
