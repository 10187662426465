<template>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ isEdit ? 'Edit API Key' : 'Create API Key' }}</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="submitForm">
            <div class="field is-small">
              <label class="label is-size-7">Name*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localApiKey.name" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Expires</label>
              <div class="control">
                <input class="input is-size-7" type="datetime-local" v-model="localApiKey.expires">
              </div>
            </div>
            <div class="field is-small" v-if="isEdit">
              <label class="label is-size-7">Key</label>
              <div class="control">
                <input class="input is-size-7" type="text" :value="localApiKey.id" readonly>
              </div>
            </div>
            <div class="field is-small" v-if="isEdit">
              <label class="label is-size-7">Revoked</label>
              <div class="control">
                <div class="select is-size-7">
                  <select v-model="localApiKey.revoked">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-small" v-if="isEdit">
              <label class="label is-size-7">Has Expired</label>
              <div class="control">
                <input class="input is-size-7" type="text" :value="localApiKey.has_expired ? 'Yes' : 'No'" readonly>
              </div>
            </div>
          </form>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-success is-small" @click="submitForm">{{ isEdit ? 'Update' : 'Create' }}</button>
          <button class="button is-small" @click="$emit('close')">Cancel</button>
          <button class="button is-danger is-small ml-2" v-if="isEdit" @click="confirmDelete" disabled>Delete</button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isEdit: Boolean,
      apiKey: Object,
    },
    data() {
      return {
        localApiKey: this.apiKey ? { ...this.apiKey } : {
          name: '',
          expires: null,
          revoked: false,
          has_expired: false,
        },
      };
    },
    methods: {
      submitForm() {
        this.$emit('submit', this.localApiKey);
      },
      confirmDelete() {
        if (confirm('Are you sure you want to delete this API Key?')) {
          this.$emit('delete', this.localApiKey.id);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .ml-2 {
    margin-left: 0.5rem;
  }
  </style>
  